<template>
  <div class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive include="/reader/book">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    key() {
     // console.log(this.$route.fullPath+"==========")
      return this.$route.fullPath
    }
  }
}
</script>

<style scoped>
</style>
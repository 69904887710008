<template>
    <div class="modal" v-show="isVisible">
        <div class="modal-overlay" @click="close"></div>
        <div class="modal-container">
            <div class="modal-header">
                <slot name="header">
                    <h3></h3>
                </slot>
            </div>
            <div class="modal-body">
                <slot>
                </slot>
            </div>
            <div class="modal-footer">
                <slot name="footer">
                    <button class="modal-default-btn" @click="close"></button>
                </slot>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Modal',
    props: {
        isVisible: Boolean,
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
  
<style>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* 阴影 */
.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
/* 内容部分 */
.modal-container {
    position: relative;
    width: 85%;
    max-width: 1300px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.modal-header {
    padding: 5px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
}

.modal-close-btn {
    font-size: 20px;
    color: #333;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}
/* 容器 */
.modal-body {
    padding: 20px 10px;
    overflow-y: auto;
    max-height: 350px;
}

/* 底部取消 */
.modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2ea;
}

.modal-default-btn {
    margin-left: 8px;
}
</style>

<template>
  <div class="sidebar-container" :style="{ backgroundColor: '#30333c' }">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeRoute" :collapse="isCollapse" router background-color="#30333c" text-color="#fff" active-text-color="#f6ca9db3"
        unique-opened @open="handleOpen" @close="handleClose" @select="selectOpen">
        <template v-for="item in routes">
          <el-submenu :index="item.path" v-if="item.children[0].children" >
            <template slot="title" >
              <svg class="icon" aria-hidden="true" >
                <use :xlink:href="'#icon' + item.children[0].meta.icon" ></use>
              </svg>
              <span >{{ item.children[0].meta.title }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="item.path" v-for="item in item.children[0].children" >{{ item.meta.title}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-else :index="item.path">
            <template slot="title">
              <svg class="icon">
                <use :xlink:href="'#icon' + item.children[0].meta.icon"></use>
              </svg>
              <span>{{ item.children[0].meta.title }}</span>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>

  </div>
</template>

<script>
import { getRole } from '@/utils/auth'

export default {
  data() {
    return {
      isCollapse: false
    }
  },
  computed: {
    routes() {
      // console.log(this.$router.options.routes.filter(item => item.children.meta.role))
      // console.log(getRole())

      // console.log(this.$router.options.routes);
      // console.log(this.$router.options.routes[3]);
      // console.log(this.$router.options.routes[3].children);
      // console.log(this.$router.options.routes[3].children[0]);
      // console.log(this.$router.options.routes[3].children[0].children);

      let role = getRole()
      return this.$router.options.routes.filter(item => {
        return !item.hidden && (item.path === '/' || item.children[0].meta.role.includes(role));
      });
    },
    activeRoute() {
      return this.$route.path
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    selectOpen(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style>
.scrollbar-wrapper.el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.el-scrollbar__bar.is-horizontal {
  display: none !important;
}

.scrollbar-wrapper.el-scrollbar__wrap {
  margin-right: -18px !important;
}
</style>

<style scoped>
.sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  /* width: 200px; */
  height: 100%;
  font-size: 0;
  transition: width 0.28s;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.el-menu:not(.el-menu--collapse) {
  width: 200px !important;
}

.el-scrollbar {
  height: 100%;
}

/* .el-menu {
  width: 100%;
} */

.el-submenu-item i .el-menu-item i {
  margin-right: 16px;
}

.icon {
  width: 18px;
  height: 18px;
  padding-right: 14px;
}
</style>
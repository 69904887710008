import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import { getToken } from '@/utils/auth'


const service = axios.create({
  //baseURL: 'https://admin.leshop.cloud',
  baseURL: 'https://api.leshop.cloud',
  //baseURL: 'https://127.0.0.1:18080',
  // 全局配置，设置token
  headers: {
    'Authorization': getToken(),
  }
})

// 全局拦截器 请求
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前，通过 getToken() 获取最新的 Token，并设置到请求头中
    config.headers['Authorization'] = getToken();

    // if(config.method === 'post'){
    //   config.data=data.params
    // }
    //config.data={}

    // 添加bid和aid
    if (config.method === 'get') {
      // GET请求使用params参数
      config.params = {
        ...config.params,
        // aid: getAid(),
        // bid: getBid()
      };
    } else if (config.method === 'post') {
      // POST请求使用data参数
      // 针对于传入的参数是数组 不做处理
      if(Array.isArray(config.data)){}
      else{
        config.data = {
          ...config.data,
        };
      }
    }
    return config;
  },
  (error) => {
    console.log("请求拦截器部分报错");

    return Promise.reject(error);
  }
);

// 全局拦截器  响应
service.interceptors.response.use(
  response => {
    //console.log(response)
    const res = response.data
    //console.log(res)
    if (res.code === '200') {
      return res;
    } else if (res.code === '405') {
      // 通过 router.push() 进行路由跳转，跳转到登录页面
      router.push('/login');
    }else if(response.status == "200"){
      return response
    }else {
      // 将错误信息抛出并统一处理
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      throw new Error(res.message || 'Error');
    }
  },
  error => {
    // 将错误信息抛出并统一处理
    Message({
      message: error.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    throw new Error(error.message || 'Error');
  }
);


export default service
